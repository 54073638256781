import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { useContext, useState } from 'react';
import { MyContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Checkout = () => {

    const storedWishlistItems = JSON.parse(localStorage.getItem('wishlistItems'));

    const [formFields, setformFields] = useState({
        name: '',
        state: '',
        steet: '',
        number: '',
        description: '',
        phone: '',
        items: storedWishlistItems,
        message: '',
        price: storedWishlistItems ? storedWishlistItems.reduce((total, item) => total + parseInt(item.price), 0) : 0,
        file: '',
    })

    const context = useContext(MyContext);
    const history = useNavigate();

    // const placeOrder = () => {

    //     if (formFields.name === "" || formFields.address == "" || formFields.pincode == "" || formFields.phoneNumber == "") {
    //         alert("All fields Required");
    //         return false;
    //     }



    //     const addressInfo = {
    //         name: formFields.name,
    //         phoneNumber: formFields.phoneNumber,
    //         address: formFields.address,
    //         pincode: formFields.pincode,
    //         date: new Date().toLocaleString(
    //             "en-US",
    //             {
    //                 month: "short",
    //                 day: "2-digit",
    //                 year: "numeric",
    //             }
    //         )
    //     }



    //     var options = {
    //         key: "",
    //         key_secret: "",
    //         amount: parseInt(context.cartTotalAmount * 100),
    //         currency: "INR",
    //         order_receipt: 'order_rcptid_' + formFields.name,
    //         name: "E-Bharat",
    //         description: "for testing purpose",
    //         handler: function (response) {

    //             // console.log(response)


    //             const paymentId = response.razorpay_payment_id
    //             // store in firebase 
    //             const orderInfo = {
    //                 cartItems: context.cartItems,
    //                 addressInfo: addressInfo,
    //                 date: new Date().toLocaleString(
    //                     "en-US",
    //                     {
    //                         month: "short",
    //                         day: "2-digit",
    //                         year: "numeric",
    //                     }
    //                 ),
    //                 email: localStorage.getItem("userEmail"),
    //                 userid: localStorage.getItem("userId"),
    //                 paymentId
    //             }




    //             history('/')


    //         },

    //         theme: {
    //             color: "#3399cc"
    //         }
    //     };


    //     var pay = new window.Razorpay(options);
    //     pay.open();
    // }

    const placeOrder = () => {
        axios.post('https://erhem.templateapi.xyz/say/order',
            formFields
        ).then((e) => {
            window.location.reload(false);
            localStorage.removeItem('wishlistItems');
        });
    }

    const changeInput = (e) => {
        const { name, value } = e.target;

        setformFields(() => ({
            ...formFields,
            [name]: value
        }))

    }

    return (
        <section className='cartSection mb-5 checkoutPage'>
            <br />
            <br />
            <br />
            <div style={{ paddingTop: '20vh' }}></div>
            <div className='container'>
                <form>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className='form w-75 mt-4 shadow'>
                                <h3>Хаяг оруулах</h3>
                                <div className='form-group mb-3 mt-4'>
                                    <TextField id="outlined-basic" label="Нэр" variant="outlined" className='w-100'
                                        value={formFields.name} onChange={changeInput} name="name" />
                                </div>
                                <div className='form-group mb-3'>
                                    <TextField id="outlined-basic" label="Дүүрэг" variant="outlined" className='w-100'
                                        value={formFields.state} onChange={changeInput} name="state" />
                                </div>
                                <div className='form-group mb-3'>
                                    <TextField id="outlined-basic" label="Хотхон, хороолол" variant="outlined" className='w-100'
                                        value={formFields.steet} onChange={changeInput} name="steet" />
                                </div>
                                <div className='form-group mb-3'>
                                    <TextField id="outlined-basic" label="Орц, тоот" variant="outlined" className='w-100'
                                        value={formFields.number} onChange={changeInput} name="number" />
                                </div>
                                <div className='form-group mb-3'>
                                    <TextField id="outlined-basic" label="Утасны дугаар" variant="outlined" className='w-100'
                                        value={formFields.phone} onChange={changeInput} name="phone" />
                                </div>
                                {/* <div className='form-group mb-3'>
                                    <TextField id="outlined-basic" label="Enter Phone Number." variant="outlined" className='w-100'
                                        value={formFields.} onChange={changeInput} name="phoneNumber" />
                                </div> */}
                                <div className='form-group'>
                                    <TextField id="outlined-basic" label="Дэлгэрэнгүй хаяг" variant="outlined" className='w-100' multiline
                                        rows={4} value={formFields.description} onChange={changeInput} name="description" />
                                </div>
                                <div className='form-group mb-3'>
                                    <TextField id="outlined-basic" label="Бялууны дээр бичүүлэх мессэж" variant="outlined" className='w-100'
                                        value={formFields.message} onChange={changeInput} name="message" />
                                </div>
                                <div className='form-group mb-3'>
                                    <input type="file" name="file" id="" />
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4 cartRightBox pt-4'>
                            <div className='card p-4 '>
                                {/* <div className='d-flex align-items-center mb-4'>
                                    <h5 className='mb-0 text-light'>Subtotal</h5>
                                    <h3 className='ml-auto mb-0 font-weight-bold'><span className='text-g'>
                                        {context.cartTotalAmount}
                                    </span></h3>
                                </div>

                                <div className='d-flex align-items-center mb-4'>
                                    <h5 className='mb-0 text-light'>Shipping</h5>
                                    <h3 className='ml-auto mb-0 font-weight-bold'><span >Free</span></h3>
                                </div>

                                <div className='d-flex align-items-center mb-4'>
                                    <h5 className='mb-0 text-light'>Total</h5>
                                    <h3 className='ml-auto mb-0 font-weight-bold'><span className='text-g'>
                                        {context.cartTotalAmount}
                                    </span></h3>
                                </div> */}
                                <div className='d-flex align-items-center mb-4'>
                                    <h5 className='mb-0 text-light'>Төлбөр төлөх</h5>
                                </div>
                                <h5 className='ml-auto mb-0 font-weight-bold'>
                                    5015168858 данс руу '
                                    <span className='text-g'>
                                        {storedWishlistItems ? storedWishlistItems.reduce((total, item) => total + parseInt(item.price), 0) : 0}
                                    </span>' төгрөг
                                    данс руу тооцоог хийж Захиалга баталгаажуулах дарна уу
                                </h5>
                                <br />
                                <Button className='btn-g btn-lg' onClick={placeOrder}>Захиалга баталгаажуулах</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Checkout;