import React, { useEffect, useContext, useState } from 'react';
import Slider from "react-slick";
import './index.css';

import Slide1 from '../../../assets/images/slider-1.png';

import { MyContext } from '../../../App';
import axios from 'axios';

const HomeSlider = () => {
    const [sliders, setSliders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isLoading) {
            axios.get('https://erhem.templateapi.xyz/say/sliders')
                .then((e) => {
                    setIsLoading(false);
                    setSliders(e.data.data);
                })
        }
    }, [isLoading])

    const context = useContext(MyContext);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        arrows: context.windowWidth > 920 ? true : false,
        autoplay: true
    };

    return (
        <section className='homeSlider'>
            <div className='container-fluid position-relative'>
                <Slider {...settings} className='home_slider_Main'>
                    {sliders.map((e) => (
                        <div className="item">
                            <img src={'https://erhem.templateapi.xyz/say/uploads/' + e.image} className='img w-100' />
                            <div className='info'>
                                <h2 class="mb-4">
                                    {e.text}
                                </h2>
                                {/* <p>Sign up for the daily newsletter</p> */}
                            </div>
                        </div>
                    ))
                    }
                    {/* <div className="item">
                        <img src={Slide2} className='w-100' />
                        <div className='info'>
                            <h2 class="mb-3">
                                Fresh Vegetables<br />
                                Big discount
                            </h2>
                        </div>
                    </div> */}
                </Slider>

                {/* {
                    context.windowWidth>992 && <Newsletter/>
                } */}


            </div>
        </section>
    )
}

export default HomeSlider;